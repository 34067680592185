import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'gatsby'
import cn from 'classnames'

import styles from '../scss/components/newsletter.module.scss'

import NameSVG from './../assets/svg/silhouette.svg'
import EmailSVG from './../assets/svg/mail.svg'
import Checkmark from './../assets/svg/checkmark.svg'
import ArrowSVG from './../assets/svg/arrow.svg'
import SpinnerSVG from './../assets/svg/spinner.svg'

const Newsletter = () => {
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const { register, handleSubmit, formState, setError, errors } = useForm()
  const { isSubmitting } = formState

  const onSubmit = async (data) => {
    setSuccess(false)
    setFailed(false)
    if (data.listIds.length > 0) {
      await fetch('/api/newsletter/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((data) => {
          if (data.status !== 200) {
            setFailed(true)
          } else {
            setSuccess(true)
          }
        })
        .catch((error) => {
          setFailed(true)
        })
    } else {
      setError('listIds', {
        type: 'manual',
        message: 'Please select at least 1 of our newsletter lists.',
      })
    }
  }

  return (
    <section id="newsletter" className={styles.newsletter} data-datocms-noindex>
      <div className="container">
        <div className={styles.block}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12">
                <h3 className={styles.title}>Stay in the loop</h3>
                <p>Sign up to your picks from our range of Newsletters</p>
                {errors.listIds && (
                  <p className={styles.success}>{errors.listIds.message}</p>
                )}
                {failed && (
                  <p className={styles.error}>
                    There was an issue when submitting the form. Please refresh
                    and try again.
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-5">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <div className={styles.inputBox}>
                  <NameSVG className={styles.icon} />
                  <input
                    ref={register}
                    type="text"
                    aria-label="Name"
                    placeholder="Name"
                    id="name"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                <label htmlFor="email" className="sr-only">
                  Email Address
                </label>
                <div className={styles.inputBox}>
                  <EmailSVG className={styles.icon} />
                  <input
                    ref={register}
                    type="email"
                    aria-label="Email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className={styles.listRowTop}>
                  <div className={styles.categoryTitle}>News</div>
                  <div className={styles.listGrid}>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="erudus"
                        type="checkbox"
                        name="listIds"
                        value="8"
                      />
                      <Checkmark />
                      Erudus
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="industry"
                        type="checkbox"
                        name="listIds"
                        value="10"
                      />
                      <Checkmark />
                      Industry
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="erudus-data-update"
                        type="checkbox"
                        name="listIds"
                        value="11"
                      />
                      <Checkmark />
                      Erudus Data Pool
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="podcast"
                        type="checkbox"
                        name="listIds"
                        value="38"
                      />
                      <Checkmark />
                      Podcast
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="release-notes"
                        type="checkbox"
                        name="listIds"
                        value="28"
                      />
                      <Checkmark />
                      Release Notes
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className={styles.listRow}>
                  <div className={styles.categoryTitle}>Editorial</div>
                  <div className={styles.listGrid}>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="food-agenda"
                        type="checkbox"
                        name="listIds"
                        value="12"
                      />
                      <Checkmark />
                      The Food Agenda
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="recipe-book"
                        type="checkbox"
                        name="listIds"
                        value="13"
                      />
                      <Checkmark />
                      Recipe book
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="insider-diaries"
                        type="checkbox"
                        name="listIds"
                        value="14"
                      />
                      <Checkmark />
                      Insider Diaries
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="erudus-commumity"
                        type="checkbox"
                        name="listIds"
                        value="16"
                      />
                      <Checkmark />
                      Erudus Community
                    </label>
                    <label className={cn(styles.listItem, styles.listCheckbox)}>
                      <input
                        ref={register}
                        id="takeaway"
                        type="checkbox"
                        name="listIds"
                        value="15"
                      />
                      <Checkmark />
                      The Takeaway
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className={styles.formFooter}>
                  {success ? (
                    <p className={styles.success}>
                      Thank you for signing up! Please check your email for a
                      confirmation link!
                    </p>
                  ) : (
                    <Fragment>
                      <label className={`${styles.listCheckbox} m-0`}>
                        <input
                          id="privacy-policy"
                          type="checkbox"
                          value="privacy-policy"
                          required
                        />
                        <Checkmark /> I agree to the{' '}
                        <Link to="/privacy-policy" className={styles.termsLink}>
                          Erudus Privacy Policy
                        </Link>
                      </label>
                      {isSubmitting && (
                        <SpinnerSVG className="mr-2" width="20" />
                      )}
                      <button
                        type="submit"
                        className="c-button c-button--secondary"
                        disabled={isSubmitting}
                      >
                        <ArrowSVG
                          className="mr-2"
                          width="16.058"
                          height="12.121"
                        />
                        Sign up
                      </button>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
